// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-jsx": () => import("./../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-commands-index-jsx": () => import("./../../src/templates/commands/index.jsx" /* webpackChunkName: "component---src-templates-commands-index-jsx" */),
  "component---src-templates-wiki-index-jsx": () => import("./../../src/templates/wiki/index.jsx" /* webpackChunkName: "component---src-templates-wiki-index-jsx" */),
  "component---src-templates-wiki-page-index-jsx": () => import("./../../src/templates/wikiPage/index.jsx" /* webpackChunkName: "component---src-templates-wiki-page-index-jsx" */)
}

